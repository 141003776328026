.home{
    height: 100vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
   
    background: linear-gradient(to right, rgba(0, 0, 1, 0.787),rgba(0, 0, 0, 0.899)),url('../Assets/header-pano.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
    
    
}
.home-text{
   
    color: #fff;
    max-width: 65ch;
}
.home-text h1{
    
    font-size : clamp(3.3rem, 3.733vw + 0.895rem, 5.375rem);
    font-weight: bold;
    z-index: 99;
   
    
}
.home-text h1:after{
  display: none;
}
.home-text p{
  font-size : clamp(1.4rem, 0.933vw + 0.63rem, 1.75rem);
}

.home-banner{

 
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.banners{
  position: absolute;
  align-items: center;
  justify-content: center;
 

  
}
/* .banners {
  width: auto;
  height: 

} */
.banners img{
  max-width: 300px;
  
}


.circle{
  
  align-items: center;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in;
  
  


}

.circle:hover{
  scale: 1.063;
  filter:grayscale(100%);
}

/* scrolltobottom */
  #section04 a span {
    position: absolute;
    bottom: 30px;
    
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb04 2s infinite;
    animation: sdb04 2s infinite;
    box-sizing: border-box;
  }
  @-webkit-keyframes sdb04 {
    0% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
    }
    20% {
      -webkit-transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
    }
  }
  @keyframes sdb04 {
    0% {
      transform: rotate(-45deg) translate(0, 0);
    }
    20% {
      transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
      transform: rotate(-45deg) translate(0, 0);
    }
  }
  @media screen and (max-width:1441px) {
    .circle{
      width: 270px;
    }
  }
  @media screen and (max-width:1441px) {
    .circle{
      width: 270px;
    }
  }
  @media screen and (max-width:1201px) {
    .circle{
      width: 240px;
    }
  }
  @media screen and (max-width:1023px) {
    .banners{
      display: none;
    }
    .home .g2{
      grid-template-columns: 1fr;
      justify-items: center;
      text-align: center;
    }
  }