.breadCrumps{
    height: 54vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    /* background: linear-gradient( rgba(104, 104, 0, 0),rgba(0, 0, 0, .1)),url('../Assets/header-pano.png'); */
    background: linear-gradient(190deg, rgba(87,87,87,1) 7%, rgba(8, 54, 97, 0.63), rgb(0, 0, 0)  57%);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
   
    
  }
  .breadText{
    position: absolute;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-wrap: wrap;
    color: white;
    max-width: 80ch;
    transform: translateY(-15%);
    padding-left: 30px;
    padding-right: 30px;
  }
  .breadTexts{
    display: flex;
    word-break: break-all;
    row-gap: 5px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    transition: all 0.4s ease;
   
    font-size: clamp(13px,1.31vw,20px);
    font-weight: 400;
    letter-spacing: 3.0px;
    color: hsla(0,0%,100%,.5);
    


  }
  .breadTexts a{
    transition: all 0.4s ease;
  }
  .breadTexts a:hover{
    color: #fff;
  }
  .breadSplit{
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 15px;
    width: 2px;
    transform: skew(-15deg);
    background-color:hsla(0,0%,100%,.5) ;
    
    margin-left: 15px;
    margin-right: 15px;
   

  }
.breadCrumps .container{
  overflow: visible;
}
.breadCrumps h2{
    
  font-size : clamp(2.7rem, 3.733vw + 0.895rem, 4.5rem);
  font-weight: bold;
  z-index: 99;
 
  
}
.breadCrumps h4{
  font-size : clamp(1.2rem, 0.933vw + 0.63rem, 1.75rem);
  font-weight: 500;
}
@media screen and (max-width:1024px) {
  .breadText{
      transform: translateY(-10%);
  }
}


@media screen and (max-width:640px) {
  .breadCrumps .container{
    margin-inline: initial;
  }
}

  @media screen and (max-width:431px) {
    .breadText{
      padding-left: 5px;
     
    
    }
    
    .flex-wrap a{
      font-size: 15px;
      
    }
    .height-65{
      height: 60px;
    }
   
  }