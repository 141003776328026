.head .container{
  padding-inline:0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: visible;
}
.mobile-head{
  display: none;
}
.desktop-nav li a:hover{
  text-shadow: 3px 3px 10px rgba(255,255,255,0.56);
  scale: 1.04;
}
header{
    display: flex;
    justify-content: center;
    padding: 10px 100px;
    position: fixed;
    align-items: center;
    padding: 2rem 1.5rem;
    color: #ffffff;
    width: 100%;
    z-index: 999;
    transition: all 300ms ease-in-out;
    padding-left: 12rem;
    padding-right: 12rem;
    transition: all .6s;
}
header nav{
  transition: all .6s;
}
header .desktop-nav ul{
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .6s;
  font-size: clamp(1rem, 2rem + 1vw, 2rem);
  font-weight: 500;
  
}
header .desktop-nav ul li a{
  margin: 0 40px;
  transition: all 0.3s ease;
}
.scroll-down .desktop-nav {
       
  transform: translate3d(0, -120%, 0);
  padding: 1rem;
  border-radius: 5px;
  
}
.scroll-up .desktop-nav ul{
  background: #171717;
  padding: 1rem;
  border-radius: 7px;
  
}
.scroll-up .desktop-nav img{
  width: 200px;
  transition: all .6s;
}
.scroll-up .logo{
  transition: all .3s ease;
}
.burger{
  display: none;
}

/* burger menu */
.vbp-header-menu-button__svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: scaleX(-1);
}

.vbp-header-menu-button__svg .line {
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  margin: 5px 0;
  transition: transform 0.3s, opacity 0.3s;
}

.menu-open .vbp-header-menu-button__svg .top {
  transform: rotate(49deg) translate(7px, 7px);
}

.menu-open .vbp-header-menu-button__svg .middle {
  opacity: 0;
}

.menu-open .vbp-header-menu-button__svg .bottom {
  transform: rotate(-50deg) translate(4px, -5px);
}

/* media queries */

@media screen and (max-width:1181px) {
  header nav ul li a{
   
    font-size: clamp(9px, 4vw ,18px);
   
  }
  .logo img{
    width: 200px;
   
   
  }
}
@media screen and (max-width:1060px) {
  header{
    
   
    padding: 2rem 1.5rem;
   
    padding-left: 7rem;
    padding-right: 7rem;
    
}
}

@media screen and (max-width:1004px ) {

  .burger{
    display: flex;
  }
 
  header nav ul{
    display: block;
  }
}
@media screen and (max-width:678px) {
  .scroll-up nav ul{
    background: none;
  }
  header{
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
   

  }
  .scroll-up header{
    background-color: #0e1514;
  }
}
@media screen and (max-width:420px) {
  .mobile{
    justify-content: space-around;
  }
}



@media screen and (max-width: 1004px) {
  .head{
   display: none;
  }
  .mobile-head{
    padding-left: 10px;
    padding-right: 10px;
   display: flex;
  }
  .hamburger{
   width: 40px;
   height: 30px;
   position: relative;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .5s ease-in-out;
   -moz-transition: .5s ease-in-out;
   -o-transition: .5s ease-in-out;
   transition: .5s ease-in-out;
   cursor: pointer;
   display: block;
 }
 
 .hamburger .line{
   display: block;
   margin-bottom: 5px;
   height: 3px;
   width: 100%;
   background: #ffffff;
   border-radius: 5px;
   opacity: 1;
   left: 0;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .25s ease-in-out;
   -moz-transition: .25s ease-in-out;
   -o-transition: .25s ease-in-out;
   transition: .25s ease-in-out;
 }
 
 .hamburger .line:nth-child(1) {
   top: 0px;
 }
 
 .hamburger .line:nth-child(2) {
   top: 18px;
   width: 80%;
 }
 
 .hamburger .line:nth-child(3) {
   top: 36px;
 }
 
 
 .hamburger.open .line:nth-child(1) {
   transform: rotate(45deg) translate(10px, 6px);
 }
 
 .hamburger.open .line:nth-child(2) {
   opacity: 0;
   left: -60px;
 }
 
 .hamburger.open .line:nth-child(3) {
   transform: rotate(-45deg) translate(5px, -4px);
 }


 .logo::before{
   content: "";
   display: block;
   width: 0;
   height: 0;
   z-index: -1;
   align-items: center;
   top: 0;
   position: absolute;
   background-color: transparent;
   padding-bottom:30px ;    
   clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);
 }

  .header-btn{
   display: none;
   
  }
  header{
   justify-content: space-between;
  }
  .nav-bar{
   height: 0;
   position: absolute;
   top: 106px;
   left:0;
   right: 0;
   width: 100vw;
   background-color: #393838eb;
   transition: 0.2s;
   align-items: center;
   justify-content: center;
   display: flex;
   overflow: hidden;
  }

  .nav-bar ul{
   display: block;
   opacity: 0;
   margin: 80px;
   text-align: center;
   transition: 0.3s;
  }
 .nav-bar ul li a{
   margin-bottom: 12px;
   font-size: 25px;
   font-weight: 600;
   color: #ffffffde;
 }
 .nav-bar.active{
   height: 70vh;
 }
 .nav-bar.active ul{
   opacity: 1;
 }


/* subbar */
.nav-bar ul li ul{
 position: relative;
 background-color: #ffffff70;
 display: flex;
 align-items: center;
 flex-direction: column;
 margin: 0;
 opacity: 1;
 
}
/* .submenu{
 
} */
/* .submenu.open{
 display: flex;
} */

.nav-bar ul li ul li{
 line-height: 1;
 width: 190px;
 border-radius: 5px;
 padding: 6px;

}
.nav-bar ul li ul li a{
 padding: 10px 0px;
 margin: 0 0;
 
 text-align: center;
 color: #000000c5;
 font-weight: 600;
 font-size: 22px;
 
}
.nav-bar ul li ul li a:hover{
 background-color:#e6e6e6 ;
 border-radius: 5px;
 color: #000;  
}
.nav-bar .fa-caret-down{
 color: #000;
}

.scroll-down .mobile-head{
       
  transform: translate3d(0, -120%, 0);
  padding: 1rem;
  border-radius: 5px;
  
}
.scroll-up .mobile-head {
  background: #171717;
  padding: 1rem;
  border-radius: 7px;
  
}
}