.banner{
   
    background-color: rgb(17, 17, 17);
    box-shadow: rgb(38, 40, 44) 3.2px 3.2px 8px 0px inset, rgb(54, 50, 50) -3.2px -3.2px 8px 0px inset;
    
}

.banner .btn{
    margin-top: 0;
}
@media screen and (max-width:768px) {
    .bnnr-bslk{
        text-align: center;
    }
    .icerik {
        justify-items: center;
    }
}