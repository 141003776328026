/* .contact .container {
  
} */

.ch50{
  max-width: 50ch;
}
.mobile-map{
  display: none;
}
.harit{
  position: absolute;
  height: 200%;
  width: 200%;
  top: -50%;
  right: -50%;
  overflow: hidden;
  --tw-translate-y: -50%;

}

.formbold-mb-5 {
    margin-bottom: 20px;
  }
  .formbold-pt-3 {
    padding-top: 12px;
  }
  .formbold-main-wrapper {
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
  }

  .formbold-form-wrapper {
    margin: 0 auto;
    max-width: 550px;
    width: 100%;

  }
  .formbold-form-label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 12px;
  }
  .formbold-form-label-2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .formbold-form-input {
    width: 100%;
    padding: 12px 24px;
    border-radius: 12px;
    border: 1px solid #e0e0e0;
    
    font-weight: 500;
    font-size: 16px;
    color: #6b7280;
    outline: none;
    resize: none;
  }
  .formbold-form-input:focus {
    border-color: var(--blueColor);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }

  .formbold-btn {
    text-align: center;
    font-size: 16px;
    border-radius: 6px;
    padding: 14px 32px;
    border: none;
    font-weight: 600;
    background-color: var(--blueColor);
    color: white;
    cursor: pointer;
  }
  .formbold-btn:hover {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }

  .formbold--mx-3 {
    margin-left: -12px;
    margin-right: -12px;
  }
  .formbold-px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .w-full {
    width: 100%;
  }

  .contact .maps{
    position: absolute;
    overflow: hidden;
    mask-image: radial-gradient(ellipse 100% 63% at 50% 50%, rgba(0, 0, 0, 1) 0, transparent 50%);
    right: 5%;
    max-width: 900px;
    width: 100%;
    height: 200%;
    z-index: 0;
    
  }
  .contact .harita{
    position: relative;
  }
  .contact .pointer{
    z-index: 999;
    display:block;
    transform: translateY(600%);
    transition: all 0.4s ease;
  }
  .contact .pointer i{
    position: block;
    color:var(--blueColor);
    cursor: pointer ;
    font-size: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(12%);
    animation-name: shadow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition: transform 0.3s ease;
    
  }

  .contact .pointer:hover{
    transform: translateY(580%);
   
  }
  
  .contact .pointer i:hover {
    animation: rotateIcon 1.5s ease-in-out 3;
   
}
.marker {
  transition: transform 0.3s ease; /* Smooth geçiş */
}

.marker:hover {
  transform: scale(1.1); /* Hover olduğunda scale 1.1 */
}


@keyframes rotateIcon {
    0% {
        transform: scaleX(0deg);
    }
    100% {
        transform: scaleX(10deg);
    
    }
}
  @keyframes shadow {
    from {filter: drop-shadow(0px 0px 0px #ffffff);}
    to {filter: drop-shadow(0px 0px 150px #ffffff);}
    
   
  }
  @media screen and (max-width:1440px) {
    .w50{
      width: -webkit-calc(49% );
      width: -moz-calc(49% );
      width: calc(49% );
    }
    .contact .g3{
      display: flex;
      flex-wrap: wrap;
    }
    .contact .maps{
      display: none;
      


    }
    .mobile-map{
      display: block;
      position: absolute;
      overflow: hidden;
      
   
      top: 120%;
      width: 100%;
      height: 100%;
      z-index: 0;
   
      mask-image: radial-gradient(ellipse 120% 110% at 50% 50%, rgba(0, 0, 0, 1) 0, transparent 50%);
    }
    .mobile-harit{
      position: absolute;
      height: 200%;
      width: 200%;
      top: -50%;
      right: -50%;
     
      
 
    }
  }
 
  @media screen and (max-width:1000px) {
    .sep-horizontal{
      width: 100%;
      
    }
    .contact .g3{
      text-align: center;
     
    }
    .ch0{
      max-width: 100%;
    }
    .w100{
      width: -webkit-calc(99% );
      width: -moz-calc(99% );
      width: calc(99% );
    }
    .mobile-map{
      display: block;
      position: absolute;
      overflow: hidden;
      
   
      top: 160%;
      width: 100%;
      height: 120%;
      z-index: 0;
   
      mask-image: radial-gradient(ellipse 130% 110% at 50% 50%, rgba(0, 0, 0, 1) 0, transparent 50%);
    }
    
  }
  @media screen and (max-width:941px) {
    .contact .maps{
    
      mask-image: radial-gradient(ellipse 120% 110% at 50% 50%, rgba(0, 0, 0, 1) 0, transparent 50%);


    }
    .harit{
      position: absolute;
      height: 200%;
      width: 200%;
      top: -50%;
      right: -50%;
      overflow: hidden;
      --tw-translate-y: -50%;
    
    }
  }

  @media (min-width: 540px) {
    .sm\:w-half {
      width: 50%;
    }
  }


  .notification {
    position: fixed;
    top: -100px; /* Bildirimi başlangıçta ekranın dışında tutuyoruz */
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
    background-color: #fff; /* Bildirim arka plan rengi */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Hafif bir gölgelendirme ekliyoruz */
    transition: top 0.3s ease-in-out; /* Bildirimin yukarı doğru hareketi için geçiş efekti */
}

.notification.success {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Bildirimi yukarıya hareket ettiriyoruz */
    background-color: #d4edda; /* Başarılı bildirim arka plan rengi */
}

.notification.error {
    top: 20px; /* Bildirimi yukarıya hareket ettiriyoruz */
    background-color: #f8d7da; /* Hata bildirimi arka plan rengi */
}

/* loading animate */
.dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #ffffff;
    position: relative;
    
    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
  }
  
  .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  
  .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
  }
  
  .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
  }
  
  .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
  }
  
  .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
  }
  
  .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
  }
  
  .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
  }
  
  .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
  }
  
  .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
  }
  
  .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
  }
  
  .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
  }
  
  .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
  }
  
  .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
  }
  
  .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
  }
  
  .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
  }
  
  .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
  }
  
  @keyframes pulse0112 {
    0%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }
  
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }