.service-wrapper{
    padding: 5% 8%;
}
.services{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.services h1{
    color: #fff;
    font-size: 5rem;
    position: relative;
}

h1:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 10%;
    height: 8px;
    width: 80%;
    border-radius: 50px;
    background-color: rgba(255,255,255,.05);
}
h1 span{
    position: absolute;
    top: 100%;
    left: 10%;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: var(--blueColor);
    animation: anim 5s linear infinite;
}
@keyframes anim{
    95%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        left: 88%;
    }
}

.services-cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 30px;
    margin-top: 80px;
}
.service-card{
    height: 350px;
    width: 370px;
    background-color: #1c2335;
    padding: 3% 8%;
    border-radius:8px ;
    transition: .6s;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}
.service-card:after{
    content: '';
    position: absolute;
    top: 150%;
    left: -200px;
    width: 120%;
    transform: rotate(50deg);
    background-color: #fff;
    height: 18px;
    filter: blur(30px);
    opacity: 0.5;
    transition: 1s;
}
.service-card:hover:after{
    width: 225%;
    top: -100%;
}
.service-card i {
    color: var(--blueColor);
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 5.5rem;
    transition: all 0.3s ease-in-out;
}
.service-card h2{
    color: #fff;
    font-size: 20px;
    font-weight: 600;

}
.service-card p{
    text-align: left;
    width: 100%;
    margin: 12px 0;
    color:rgba(255, 255, 255, 0.6) ;
}
.service-card:hover{
    background-color: transparent;transform: translateY(-8px);
    border-color:var(--blueColor) ;
}
.service-card:hover i{
    scale: 1.15;
    filter:drop-shadow(0px 5px 7px rgba(37, 83, 132, 0.796)) ;
}

@media screen and (max-width:1200px) {
    .services-cards{
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width:900px) {
    .services-cards{
        grid-template-columns: repeat(1,1fr);
    }
    .services h1{
        font-size: 2.5rem;
    }
}