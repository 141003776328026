.footer-top{
  
    justify-items: center;
    align-items: flex-start;
}
.footer-logo ul{
    display: flex;
    justify-content: center;
    gap: 25px;
    font-size: clamp(15px , 4vw , 20px);
    

}
.footer-logo ul li i{
    transition: all 0.2s ease-out;
}
.footer-logo ul li i:hover{
    scale: 1.2;
    filter:drop-shadow(0px 0px 7px rgb(255, 255, 255)) ;
}

.footer-contact ul li a{
    display: inline-block;
}
.footer-contact{
    position: relative;
  
}
.footer-contact h3{
    font-size: 23px;
    padding-bottom: 10px;
}
.footer-contact ul li{
    padding-bottom:0.7rem;
   
}
.footer-contact ul li a{
    transition: all 0.2s ease-out;
}
.footer-contact .social li i{
    padding-right: 1.3rem;
}
.footer-contact:nth-child(2) a:hover{
   margin-left: 10px;
   filter:drop-shadow(0px 0px 7px rgb(255, 255, 255)) ;
}

.copyright{
    display: flex;
    justify-content: center;
    padding: 2rem;
    text-align: center;
}
.copyright .seperator{
    border: 1px solid white;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: aqua;
}
hr{
  margin-left: 20%;
  margin-right: 20%;
  border-style: solid;
  color: rgba(255, 255, 255, 0.347);
 
    
    
}

@media screen and (max-width:768px) {
    .footer-top.g3{
        grid-template-columns: 1fr;
        justify-items: baseline;
        row-gap: 3rem;
    }
    .footer-logo img{
        max-width: 400px;
        width: 100%;
        height: auto;
    }
    
}