@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100..900;1,100..900&display=swap');

/* RESET */

/* scroll settings */
body::-webkit-scrollbar {
  width: 0.5em; 
}
/* output */
body::-webkit-scrollbar-thumb {
  
  
  
  background-image: -webkit-gradient(linear,
                     left bottom,
                     left top,
                     color-stop(0.44, rgb(0, 0, 0)),
                     color-stop(0.72, rgb(70, 70, 70)),
                     color-stop(0.86, rgb(0, 0, 0)));

}
body::-webkit-scrollbar-thumb:hover{
  opacity:1;
}
body::-webkit-scrollbar-track {
  
  background-color: transparent;
  
}

body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
 
}
/* selection */
::selection{
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standart yazım */
}
/* scroll settings */


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Saira", sans-serif;
  
}
li { list-style: none; }
  
a {
  text-decoration: none;
  color: inherit;
}
span{
  display: inline;
}
a,
img,
time,
span,
input,
button,
ion-icon { display: block; }

img { height: auto; }

input,
button {
  background: none;
  border: none;
  font: inherit;
}

input { width: 100%; }

button { cursor: pointer; }

ion-icon { pointer-events: none; }

html {
  font-family: var(--ff-rubik);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: #000000;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.7;
 
}
.flex{
  display: flex;
  align-items: center;
}
.grid{
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 1rem;
}

input{
  font-size: 100%;
}
.spanText{
  font-size: 14px;
}

:focus-visible { outline-offset: 4px; }

::-webkit-scrollbar { width: 12px; }

::-webkit-scrollbar-track { background-color: var(--cultured-2); }

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
  border: 2px solid var(--cultured-2);
}

::-webkit-scrollbar-thumb:hover { background-color: hsl(0, 0%, 70%); }

/* ROOT */

:root{
  --grayColor:#a0a0a1;
  --lightGrayColor:#cccccc;
  --redColor:#eb2f5b;
  --titleColor:#454545;
  --blueColor:#2f5beb;
  --textGray:#797979;
  --blueColor:#50b6e6;
}
/* grid */
.g2{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0px;
  align-items: center;
}
.g2c{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0px;
  justify-items: right;
  align-items: center;
}
.g3{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2%;
  align-items: center;
}
.g200{
  display: grid;
  grid-template-columns: 38% 38% 20%;
  grid-column-gap: 2%;
  align-items: center;
}
.g4{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
 
  grid-column-gap: 2%;
  align-items: center;
  
}

@media screen and (max-width:1200px) {
  .g4{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    grid-row-gap: 10%;
    grid-column-gap: 5%;
    align-items: center;
    
  }
}
@media screen and (max-width:768px) {
  .g2c{
    grid-template-columns: 1fr;
    justify-content: center;
    grid-row-gap: 12%;
  }
}

/* padding */
.p1{
  padding: 1rem;
}
.p2{
  padding: 2rem;
}
.p3{
  padding: 3rem;
}
.p4{
  padding: 4rem;
}
.p5{
  padding: 5rem;
}


.pr1{
  padding-right: 1rem;
}
.pr2{
  padding-right: 2rem;
}
.pr3{
  padding-right: 3rem;
}
.pr4{
  padding-right: 4rem;
}
.pr5{
  padding-right: 5rem;
}

.pl1{
  padding-left: 1rem;
}
.pl2{
  padding-left: 2rem;
}
.pl3{
  padding-left: 3rem;
}
.pl4{
  padding-left: 4rem;
}
.pl5{
  padding-left: 5rem;
}

.pb1{
  padding-bottom: 1rem;
}
.pb2{
  padding-bottom: 2rem;
}
.pb3{
  padding-bottom: 3rem;
}
.pb4{
  padding-bottom: 4rem;
}
.pb5{
  padding-bottom: 5rem;
}
.pt1{
  padding-top: 1rem;
}
.pt2{
  padding-top: 2rem;
}
.pt3{
  padding-top: 3rem;
}
.pt4{
  padding-top: 4rem;
}
.pt5{
  padding-top: 5rem;
}

.py1{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py2{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py3{
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py4{
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.my1{
  margin-top: 1rem;
  margin-bottom:1rem;
}
.my2{
  margin-top: 2rem;
  margin-bottom:2rem;
}
.my3{
  margin-top: 3rem;
  margin-bottom:3rem;
}
.my4{
  margin-top: 4rem;
  margin-bottom:4rem;
}

.mt1{
  margin-top: 1rem;
  
}
.mt2{
  margin-top: 2rem;

}
.mt3{
  margin-top: 3rem;
 
}
.mt4{
  margin-top: 4rem;
 
}
.mb1{
  margin-bottom: 1rem;
  
}
.mb2{
  margin-bottom: 2rem;

}
.mb3{
  margin-bottom: 3rem;
 
}
.mb4{
  margin-bottom: 4rem;
 
}
.clgap{
  grid-column-gap:1rem ;
}
/* seperator */
.sep-horizontal{
  background-color: #cccccc53;
  width: 45%;
  height: 1px;
  
}
/* button */
.btn{
  margin-top: 4rem;
  background-color: var(--blueColor);
  color: transparent;
  border: 0 none;
  padding: 15px 30px;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 300ms ease, color 300ms ease;
  font-size: 17px;
  position: relative;
  text-align: center;
  cursor: pointer;
 }
 
 .btn::before,
 .btn::after {
  content: attr(data-label);
  display: inline-block;
  position: absolute;
  transition: all 300ms ease;
  width: 100%;
  left: 0;
  top: 50%;
  color: white;
 }
 
 .btn::before {
  opacity: 0;
  transform: translateY(calc(-50% - 15px));
 }
 
 .btn::after {
  opacity: 1;
  transform: translateY(-50%);
 }
 
 .btn:hover {
 
  background-color: var(--grayColor);
 }
 
 .btn:hover::before {
  opacity: 1;
  transform: translateY(-50%);
 }
 
 .btn:hover::after {
  opacity: 0;
  transform: translateY(calc(-50% + 15px));
 }
 
 .btn:active {
  background-color: rgb(82, 163, 255);
 }

/* text */

.crd-txt{
  color: #c5c5c5;
  font-weight: 300;
  font-size: 1.6rem;
}
.txt{
  font-size: clamp(1.40rem, 0.4vw + 0.708rem, 1.9rem);
  color: #c5c5c5;
  letter-spacing: 0.6px;
  line-height: 33px;
}
.txt2{
  font-size: clamp(1.7rem, 0.4vw + 0.708rem, 2.5rem);
  color: #d6d6d6;
  letter-spacing: 0.4px;
  line-height: 33px;
}

/* header */

.syf-bslk{
  letter-spacing: 1.3px;
  font-size: clamp(2.5rem, 2.2vw + 0.36rem, 4.8rem);
  font-family: "Saira", sans-serif;
}
.bslk{
  letter-spacing: 1.3px;
  font-size: clamp(1.8rem, 2.2vw + 0.36rem, 3.5rem);
  font-family: "Saira", sans-serif;
}
.crd-bslk{
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.7px;
  font-weight: 600;
}
.bnnr-bslk{
  font-size:2.4rem;
}
/* icon */
.icon{
  font-size: 5.6rem;
  padding-bottom: 3rem;
  transition: all 0.3s ease-in-out;
}


.container{padding-inline: 15px; padding-top: 6rem; padding-bottom: 3rem; overflow: hidden; }

 @media screen and (min-width:640px) {
  .container {
    max-width: 540px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (max-width:600px) {
  .container {
    max-width: 400px;
    width: auto;
    margin-inline: auto;
   
  }
 
 }
 @media screen and (min-width:768px) {
  .container {
    max-width: 708px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (min-width:992px) {
  .container {
    max-width: 932px;
    width: 100%;
    margin-inline: auto;
  }
 }

 @media screen and (min-width:1024) {
  .container {
    max-width: 964px;
    width: 100%;
    margin-inline: auto;
  }
  
 }
 @media screen and (min-width:1200px) {
  .container {
    max-width: 1140px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (min-width:1440px) {
  .container {
    max-width: 1380px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (max-width:1024px) {
  .cardBaslik{
    font-size: calc(1.6rem + 1vw);
  }
 }
 