.WpButton{
    position: fixed;
    transform: all .5s ease;
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    border-right: none;
    color: #fff;
   
    z-index: 2;
    border: 0;
    font-weight: 700;
    right: 10px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(0px) saturate(168%);
    -webkit-backdrop-filter: blur(0px) saturate(168%);
    background-color: rgba(80, 182, 230, 0.61);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    border-top-left-radius:20px;
    border-bottom-right-radius:20px;
    transition: all .4s ease;
}
.WpButton:hover{
    background-color: rgba(123, 192, 223, 0.53);
    border-top-right-radius:20px;
    border-bottom-left-radius:20px;
}
.WpButton img{
    pointer-events: none;
    
}
@media screen and (min-width:768px) {
    .WpButton{
        bottom: 70px;
        border-top-left-radius:12px;
        border-bottom-right-radius:12px;
        border-top-right-radius:20px;
        border-bottom-left-radius:20px;
    }
    .WpButton:hover{
        border-top-left-radius:20px;
        border-bottom-right-radius:20px;
    }
}