.elektrik-kablo{
    width: 100%;
    height: 70vh;
    overflow:hidden;
    position: absolute;
    opacity: 0.2;
    filter:grayscale(30%) blur(8px);
}
.service .icerik{
    text-align: center;
}

/* card */

.service-cards .card{
    background: rgba( 204, 204, 204, 0.2 );
    box-shadow: 0 8px 32px 0 rgba(66, 66, 66, 0.37);
    backdrop-filter: blur( 6px );
    -webkit-backdrop-filter: blur( 7px );
    border-radius: 8px;
    align-items: center;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    
    
}
.service-cards h3{
    transition: all 0.3s ease-in-out;
    letter-spacing: 0.7px;
    font-weight: 600;
}
.service-cards .card i{
    font-size: 5.6rem;
    padding-bottom: 3rem;
    transition: all 0.3s ease-in-out;
    
}
.service-cards .card:hover i{
    scale: 1.15;
    
    filter:drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.385)) ;
}

.service-cards .card:hover{
    scale: 1.05;
}
.service-cards:has(.card:hover)
.card:not(:hover){
    filter: blur(1.5px);
}

/* card hover effect */
.service-cards .card:after {
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    left: -200%;
    width: 100%;
    height: 100%;
    transform: skewX(-20deg);
    background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2), transparent);
}

.card:active {
    transform: translateY(1px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, .3);
}

.card:hover:after {
    animation: shine 1s ease;
}


@keyframes shine {
    100% {
        left: 200%;
    }
}


/* media queries */


@media screen and (max-width:1008px) {
    .elektrik-kablo{
        width: 100%;
        height: 130vh;
        overflow:hidden;
        position: absolute;
        opacity: 0.2;
        filter:grayscale(30%) blur(8px);

    }
}