.about{

}
.vzyn-field{
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    position: relative;
    gap: 250px;

}
.vm-field{
    position: relative;
}
.vyznSplit{
    content: "";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
    width: 2px;
    top: 0;
    left: 49%;
   
    background-color:hsla(0, 0%, 71%, 0.337) ;
    --tw-translate-x: -50%;
    margin-left: 15px;
    margin-right: 15px;
}
.vizyon-group{
    overflow: hidden;
    position: relative;
}

@media screen and (max-width:786px) {
    .vzyn-field{
       display: flex;
       flex-direction: column;
       gap: 50px;
    
    }
    .vyznSplit{
       display: none;
    }
}