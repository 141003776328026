.blog{
    display: grid;
    grid-template-columns: 70% 20%;
    gap: 7%;
}

.widget{
    margin: 0 0 42px;
}
.widget h2{
    backdrop-filter: blur(0px) saturate(168%);
    -webkit-backdrop-filter: blur(0px) saturate(168%);
    background-color: rgba(37, 112, 182, 0.598);
   
    text-align: center;
    color: #fff;
    letter-spacing: .9px;
    margin-bottom: 18px;
    font-size: 18px;
    border-radius: 7px;
    padding: 10px;
}
.widget h3{
    backdrop-filter: blur(0px) saturate(168%);
    -webkit-backdrop-filter: blur(0px) saturate(168%);
    background-color: rgba(48, 48, 48, 0.53);
    text-align: left;
    color: #fff;
    letter-spacing: .9px;
    margin-bottom: 14px;
    font-size: 14px;
    border-radius: 7px;
    padding: 5px;
    padding-left: 18px;
    font-weight: 400;
}
.sidebar-icerik{
    backdrop-filter: blur(0px) saturate(168%);
    -webkit-backdrop-filter: blur(0px) saturate(168%);
    background-color: rgba(3, 137, 247, 0.436);
    padding: 20px;
    border-radius: 8px;
}
.sidebar-icerik h3{
    font-size: 16px;
    margin-bottom: 20px;
}
.sidebar-icerik p{
    font-size: 14px;
    
}
.sidebar-icerik .btn{
    margin-top: 2rem;
    padding: 10px 20px;
    font-size: 14px;
}
@media screen and (max-width:1210px) {
    .blog{
        display: grid;
        grid-template-columns: 68% 22%;
        gap: 7%;
    }
}
@media screen and (max-width:992px) {
    .blog{
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
    }
    .widget{
        margin-top: 6rem;
    }
}