.seperator{
    position: absolute;
    width:2px;
    top:40%;
    height: 50px;
    margin-left:-50px;
    padding:0 2px;
    background:var(--grayColor);
    transition: all 0.3s ease-in-out;
}
.ft-about{
    padding-left: 100px;
    position: relative;
}
.featured .ft-about:hover .seperator{
    background:#fff;
    box-shadow:0px 0px 10px rgb(255, 255, 255) ;
    transform: scaleY(2);
}
.multimetre{
    max-width: 600px;
    width: 100%;
    height: auto;
}

/* mediaqueries */
@media screen and (max-width:1023px) {
  
    .featured .g2{
        grid-template-columns: 1fr;
        grid-row-gap: 5%;
        justify-items: center;
        
    }
    .seperator{
       
        width:2px;
        top:42%;
        height: 30px;
        margin-left:-50px;
        padding:0 2px;
        background:var(--grayColor);
        transition: all 0.3s ease-in-out;
    }
}
@media screen and (max-width:767px) {
  
    .seperator{
       display: none;
    }
    .ft-about{
        padding-left: 0;
        
    }
    .featured{
        text-align: center;
    }
}